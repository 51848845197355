export const CHANCE = [
  [1, 0, 0, 0, 0], //一级
  [1, 0, 0, 0, 0], // 二级
  [0.75, 0.25, 0, 0, 0], // 三级
  [0.5, 0.3, 0.2, 0, 0], // 四级
  [0.4, 0.33, 0.25, 0.02, 0], // 五级
  [0.25, 0.4, 0.3, 0.05, 0], // 六级
  [0.19, 0.3, 0.35, 0.15, 0.01], // 七级
  [0.16, 0.2, 0.35, 0.25, 0.04], // 八级
  [0.09, 0.15, 0.3, 0.3, 0.16], // 九级
  [0.05, 0.1, 0.2, 0.4, 0.25], // 十级(高端购物, 升级咯)
  [0.01, 0.02, 0.12, 0.1, 0.35], // 十一级(升级咯+高端购物)
];

export const EXPERIENCE = [2, 2, 6, 10, 24, 40, 60, 84, 100];
export const CARDS = {
  lv1Cards: [
    {
      level: 1,
      name: "凯尔",
      star: 1,
      entanglement: ["德玛西亚", "裁决战士"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/892.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/892.png",
    },
    {
      level: 1,
      name: "崔丝塔娜",
      star: 1,
      entanglement: ["约德尔人", "枪手"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/916.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/916.png",
    },
    {
      level: 1,
      name: "科加斯",
      star: 1,
      entanglement: ["虚空", "格斗家"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/874.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/874.png",
    },
    {
      level: 1,
      name: "艾瑞莉娅",
      star: 1,
      entanglement: ["艾欧尼亚", "挑战者"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/881.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/881.png",
    },
    {
      level: 1,
      name: "茂凯",
      star: 1,
      entanglement: ["暗影岛", "堡垒卫士"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/897.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/897.png",
    },
    {
      level: 1,
      name: "雷克顿",
      star: 1,
      entanglement: ["恕瑞玛", "格斗家"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/902.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/902.png",
    },
    {
      level: 1,
      name: "发条魔灵",
      star: 1,
      entanglement: ["幻灵战队", "吉祥物"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/899.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/899.png",
    },
    {
      level: 1,
      name: "卡西奥佩娅",
      star: 1,
      entanglement: ["源计划", "斗士"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/873.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/873.png",
    },
    {
      level: 1,
      name: "波比",
      star: 1,
      entanglement: ["幻灵战队", "混沌战士"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/900.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/900.png",
    },

    {
      level: 1,
      name: "马尔扎哈",
      star: 1,
      entanglement: ["超级英雄", "决斗大师"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/896.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/896.png",
    },
    {
      level: 1,
      name: "戏命师",
      star: 1,
      entanglement: ["小天才", "爱心使者"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/884.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/884.png",
    },
    {
      level: 1,
      name: "佛耶戈",
      star: 1,
      entanglement: ["小天才", "护卫"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/920.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/920.png",
    },
    {
      level: 1,
      name: "莎米拉",
      star: 1,
      entanglement: ["机甲", "护卫"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/904.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/904.png",
    },
  ],
  lv2Cards: [
    {
      level: 2,
      name: "加里奥",
      star: 1,
      entanglement: ["福牛守护者", "小天才", "灵能使"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/877.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/877.png",
    },
    {
      level: 2,
      name: "索拉卡",
      star: 1,
      entanglement: ["至高天", "黑客"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/911.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/911.png",
    },
    {
      level: 2,
      name: "提莫",
      star: 1,
      entanglement: ["魔盗团", "斗士", "秘术"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/915.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/915.png",
    },
    {
      level: 2,
      name: "沃里克",
      star: 1,
      entanglement: ["AI", "混沌战士"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/921.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/921.png",
    },
    {
      level: 2,
      name: "艾希",
      star: 1,
      entanglement: ["机甲", "精英战士"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/870.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/870.png",
    },
    {
      level: 2,
      name: "卡萨丁",
      star: 1,
      entanglement: ["超级英雄", "爱心使者", "斗士"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/890.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/890.png",
    },
    {
      level: 2,
      name: "斯维因",
      star: 1,
      entanglement: ["超级英雄", "吉祥物"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/912.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/912.png",
    },
    {
      level: 2,
      name: "塔利亚",
      star: 1,
      entanglement: ["未来战士", "枪手"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/913.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/913.png",
    },
    {
      level: 2,
      name: "金克斯",
      star: 1,
      entanglement: ["源计划", "决斗大师"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/885.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/885.png",
    },
    {
      level: 2,
      name: "劫",
      star: 1,
      entanglement: ["魔盗团", "平行宇宙", "迅捷射手"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/923.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/923.png",
    },
    {
      level: 2,
      name: "克烈",
      star: 1,
      entanglement: ["福牛守护者", "决斗大师"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/893.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/893.png",
    },
    {
      level: 2,
      name: "薇",
      star: 1,
      entanglement: ["幻灵战队", "淘气包"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/919.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/919.png",
    },
    {
      level: 2,
      name: "瑟提",
      star: 1,
      entanglement: ["星之守护者", "护卫"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/907.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/907.png",
    },
  ],
  lv3Cards: [
    {
      level: 3,
      name: "娑娜",
      star: 1,
      entanglement: ["福牛守护者", "秘术", "吉祥物"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/910.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/910.png",
    },
    {
      level: 3,
      name: "卡尔玛",
      star: 1,
      entanglement: ["未来战士", "护卫", "黑客"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/889.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/889.png",
    },
    {
      level: 3,
      name: "塔里克",
      star: 1,
      entanglement: ["小天才", "淘气包"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/914.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/914.png",
    },
    {
      level: 3,
      name: "卡特琳娜",
      star: 1,
      entanglement: ["机甲", "斗士"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/891.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/891.png",
    },
    {
      level: 3,
      name: "盖伦",
      star: 1,
      entanglement: ["星之守护者", "迅捷射手"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/878.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/878.png",
    },
    {
      level: 3,
      name: "德莱厄斯",
      star: 1,
      entanglement: ["AI", "灵能使", "黑客"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/875.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/875.png",
    },
    {
      level: 3,
      name: "杰斯",
      star: 1,
      entanglement: ["怪兽"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/883.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/883.png",
    },
    {
      level: 3,
      name: "丽桑卓",
      star: 1,
      entanglement: ["星之守护者", "决斗大师"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/894.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/894.png",
    },
    {
      level: 3,
      name: "维克兹",
      star: 1,
      entanglement: ["怪兽"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/918.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/918.png",
    },
    {
      level: 3,
      name: "阿克尚",
      star: 1,
      entanglement: ["幻灵战队", "斗士", "护卫"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/868.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/868.png",
    },
    {
      level: 3,
      name: "艾克",
      star: 1,
      entanglement: ["魔盗团", "灵能使", "爱心使者"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/876.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/876.png",
    },
    {
      level: 3,
      name: "雷克塞",
      star: 1,
      entanglement: ["幻灵战队", "决斗大师"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/901.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/901.png",
    },
    {
      level: 3,
      name: "卡莉斯塔",
      star: 1,
      entanglement: ["至高天", "吉祥物"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/888.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/888.png",
    },
  ],
  lv4Cards: [
    {
      level: 4,
      name: "厄加特",
      star: 1,
      entanglement: ["星之守护者", "淘气包", "秘术"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/917.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/917.png",
    },
    {
      level: 4,
      name: "嘉文四世",
      star: 1,
      entanglement: ["未来战士", "灵能使", "决斗大师"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/882.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/882.png",
    },
    {
      level: 4,
      name: "内瑟斯",
      star: 1,
      entanglement: ["怪兽"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/898.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/898.png",
    },
    {
      level: 4,
      name: "慎",
      star: 1,
      entanglement: ["机甲", "护卫"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/908.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/908.png",
    },
    {
      level: 4,
      name: "拉克丝",
      star: 1,
      entanglement: ["幻灵战队", "精英战士", "迅捷射手"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/895.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/895.png",
    },
    {
      level: 4,
      name: "瑟庄妮",
      star: 1,
      entanglement: ["星之守护者", "灵能使"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/905.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/905.png",
    },
    {
      level: 4,
      name: "卡莎",
      star: 1,
      entanglement: ["福牛守护者", "混沌战士", "爱心使者"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/887.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/887.png",
    },
    {
      level: 4,
      name: "亚索",
      star: 1,
      entanglement: ["源计划", "AI", "斗士"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/922.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/922.png",
    },
    {
      level: 4,
      name: "泽丽",
      star: 1,
      entanglement: ["怪兽"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/924.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/924.png",
    },
    {
      level: 4,
      name: "阿兹尔",
      star: 1,
      entanglement: ["怪兽"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/871.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/871.png",
    },
    {
      level: 4,
      name: "厄斐琉斯",
      star: 1,
      entanglement: ["魔盗团", "精英战士", "枪手"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/869.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/869.png",
    },
    {
      level: 4,
      name: "格温",
      star: 1,
      entanglement: ["魔盗团", "精英战士", "枪手"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/879.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/879.png",
    },
  ],
  lv5Cards: [
    {
      level: 5,
      name: "瑞兹",
      star: 1,
      entanglement: ["怪兽"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/903.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/903.png",
    },
    {
      level: 5,
      name: "塞恩",
      star: 1,
      entanglement: ["气象主播", "灵能使"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/909.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/909.png",
    },
    {
      level: 5,
      name: "黑默丁格",
      star: 1,
      entanglement: ["机甲", "混沌战士", "秘术"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/880.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/880.png",
    },
    {
      level: 5,
      name: "阿狸",
      star: 1,
      entanglement: ["源计划", "精英战士"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/867.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/867.png",
    },
    {
      level: 5,
      name: "卑尔维斯",
      star: 1,
      entanglement: ["未来战士", "平行宇宙", "抢手"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/872.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/872.png",
    },
    {
      level: 5,
      name: "塞纳",
      star: 1,
      entanglement: ["小天才", "吉祥物"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/906.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/906.png",
    },
    {
      level: 5,
      name: "亚托克斯",
      star: 1,
      entanglement: ["星之守护者", "爱心使者"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/866.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/866.png",
    },
    {
      level: 5,
      name: "奎桑提",
      star: 1,
      entanglement: ["怪兽"],
      img: "https://game.gtimg.cn/images/lol/tftstore/s9/624x318/886.jpg",
      avatar: "https://game.gtimg.cn/images/lol/act/img/tft/champions/886.png",
    },
  ],
};
